<template>
  <div class="full-height gradient-background">
    <div class="d-flex flex-column justify-space-between full-height">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="12" sm="10" md="8" lg="8" xl="8">
          <div
            class="white-background form-card pa-5"
            :class="{
              rounded: !$vuetify.breakpoint.xs,
              'my-6': !$vuetify.breakpoint.xs
            }"
          >
            <div class="d-flex align-center mb-4">
              <v-img
                src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                max-width="165"
              />
              <span class="px-5 text-h5 text-left">
                Semantic Search (Change me I beg you) {{ companyId }}
                {{ projectId }}
              </span>
            </div>
            <div class="py-6">
              <div v-if="!companyId">
                Please load this page from within Procore
              </div>
              <div v-else-if="genericError">
                An unknown error occurred. Please contact Whistle for assistance
                at
                <a href="mailto:help@wewhistle.com">help@wewhistle.com</a>
              </div>
              <div
                v-else-if="loadingInitialState"
                class="d-flex flex-column align-center"
              >
                Loading...please wait
                <v-progress-circular
                  indeterminate
                  color="brandCyan"
                  width="4"
                  size="55"
                  class="mt-6"
                ></v-progress-circular>
              </div>
              <div v-else-if="!clientId" class="mx-10">
                <p>
                  Before you can search your photos, Whistle will need to
                  connect to Procore and process the images. This can take a few
                  minutes depending on how many images you have.
                </p>
                <v-btn
                  @click="linkCompany"
                  color="brandCyan"
                  class="white--text"
                  depressed
                  :loading="loadingClientSetup"
                  >Connect</v-btn
                >
              </div>
              <div v-else class="mx-15">
                <div class="d-flex align-center justify-center mx-8">
                  <v-text-field
                    v-if="!displayImageUpload"
                    outlined
                    hide-details
                    color="brandCyan"
                    label="Search for an object or situation..."
                    class="search-field search"
                    clearable
                    v-model="search"
                    @keyup.enter="searchImages(true)"
                    ><template v-slot:append-outer>
                      <!-- <v-btn color="brandCyan" depressed>
                        <v-icon color="white">
                          mdi-magnify
                        </v-icon>
                      </v-btn> -->
                    </template>
                  </v-text-field>
                  <v-file-input
                    v-else
                    v-model="imageFile"
                    label="Drag an image file, or click here to upload"
                    outlined
                    clearable
                    hide-details
                    chips
                    accept="image/*"
                    color="brandCyan"
                    class="search-field search"
                    @drop.prevent="imageFileChanged($event, true)"
                    @dragover.prevent
                    ><template v-slot:label>
                      <p>
                        Drag an image file, or click here to upload<v-icon
                          class="ml-2"
                          >mdi-upload</v-icon
                        >
                      </p>
                    </template></v-file-input
                  >
                  <!--       @click="$refs.uploader.click()" -->
                  <v-btn
                    depressed
                    class="search camera-button"
                    :disabled="loadingQuery"
                    @click="toggleFileSearch"
                  >
                    <v-icon color="darkGrey">
                      {{
                        displayImageUpload
                          ? "mdi-text-search"
                          : "mdi-camera-plus"
                      }}
                    </v-icon>
                  </v-btn>
                  <!-- <input
                    id="uploader"
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="imageFileChanged($event, false)"
                    @blur="imageFileChanged($event, false)"
                  /> -->
                  <v-btn
                    depressed
                    class="search search-button"
                    @click="searchImages(true)"
                    :loading="loadingQuery"
                  >
                    <v-icon color="white">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </div>
                <p v-if="queryError" class="error--text mt-4">
                  {{ queryError }}
                </p>
                <div class="d-flex justify-end align-center mx-7 mt-2 pb-4">
                  <div
                    class="d-flex cursor-pointer"
                    @click="displayFilters = !displayFilters"
                  >
                    <v-icon color="brandCyan" small class="mr-1"
                      >mdi-filter</v-icon
                    ><span class="brandCyan--text">Advanced Search</span
                    ><v-icon color="brandCyan">{{
                      displayFilters ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}</v-icon>
                  </div>
                </div>
                <v-expand-transition
                  ><div v-if="displayFilters" class="d-flex mx-8">
                    <v-row
                      justify="center"
                      align="start"
                      class="full-width advanced-filters-box pa-3"
                      no-gutters
                    >
                      <v-col
                        cols="12"
                        sm="5"
                        md="5"
                        lg="5"
                        xl="5"
                        class="px-1 text-left"
                      >
                        <p>Date Range:</p>
                        <v-text-field
                          v-model="startDate"
                          dense
                          outlined
                          hide-details
                          append-icon="mdi-calendar"
                          color="brandCyan"
                          label="Start date"
                          class="filter-field mb-3"
                        />
                        <v-text-field
                          v-model="endDate"
                          dense
                          outlined
                          hide-details
                          append-icon="mdi-calendar"
                          color="brandCyan"
                          label="End date"
                          class="filter-field"
                        />
                      </v-col>
                      <v-col cols="12" sm="5" md="5" lg="5" xl="5" class="px-1">
                        <p class="hidden">Date Range:</p>
                        <v-text-field
                          v-model="projectName"
                          dense
                          outlined
                          hide-details
                          color="brandCyan"
                          label="Project name"
                          class="filter-field"
                        />
                        <v-text-field
                          v-model="uploaderName"
                          dense
                          outlined
                          hide-details
                          color="brandCyan"
                          label="Uploader name"
                          class="filter-field mt-3"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="2"
                        lg="2"
                        xl="2"
                        class="d-flex justify-end align-end full-height"
                      >
                        <v-btn
                          color="brandCyan"
                          depressed
                          class="white--text"
                          width="120"
                          :loading="loadingQuery"
                          @click="searchImages(true)"
                          >Apply</v-btn
                        ></v-col
                      >
                    </v-row>
                  </div></v-expand-transition
                >
                <!-- <div class="image-container mt-8 d-flex flex-wrap">
                  <div v-for="(image, i) in results" :key="i">
                    <v-img :src="image.thumbnail_path" width="100" height="100">
                      <template v-slot:placeholder>
                        <span class="">
                          Blocked image
                        </span>
                      </template></v-img
                    >
                  </div>
                </div> -->
              </div>
            </div>
          </div></v-col
        >
      </v-row>
      <v-row
        justify="center"
        align="center"
        class="full-width"
        no-gutters
        v-if="results.length"
      >
        <v-col cols="12" sm="10" md="8" lg="8" xl="8">
          <div
            class="white-background form-card pa-5"
            :class="{
              rounded: !$vuetify.breakpoint.xs,
              'mb-6': !$vuetify.breakpoint.xs
            }"
          >
            <div class="py-6">
              <div class="mx-15">
                <div
                  class="image-container mt-8 d-flex justify-center flex-wrap"
                >
                  <div v-for="(image, i) in results" :key="i" class="mx-2">
                    <v-img
                      :src="image.thumbnail_path"
                      width="150"
                      height="150"
                      @click="loadImagePopup(image)"
                    >
                      <template v-slot:placeholder>
                        <v-img src="@/assets/Demo/crane.png" />
                        <!-- <span class="">
                          Blocked image
                        </span> -->
                      </template></v-img
                    >
                  </div>
                </div>
                <v-btn
                  v-if="loadMoreResultsButton"
                  depressed
                  color="brandCyan"
                  class="white--text"
                  :loading="loadingQuery"
                  @click="searchImages(false)"
                  >+ Load more</v-btn
                >
              </div>
            </div>
          </div></v-col
        >
      </v-row>
    </div>

    <v-dialog v-model="displayImagePopup" width="600">
      <v-card v-if="displayImagePopup" class="pa-4">
        <div class="d-flex justify-space-between">
          <div class="d-flex flex-column mt-2 text-left word-break">
            <p class="mb-1">
              {{ toLocalDate(popup.takenDate || popup.uploadDate, true) }}
            </p>
            <p class="mb-1">
              {{ popup.projectName }} - Project {{ popup.projectId }}
            </p>
          </div>
          <v-btn color="brandCyan" text v-if="!popup.failed && popup.raw_path"
            >Download<v-icon>mdi-download</v-icon></v-btn
          >
        </div>
        <v-img
          v-if="popup.failed"
          src="@/assets/placeholder_image.png"
          width="90%"
          contain
          class="mx-auto mt-4"
        >
        </v-img>
        <v-img
          v-else
          :src="popup.raw_path"
          width="90%"
          contain
          class="mx-auto mt-4"
          v-on:error="failedToLoadImage"
        ></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import ProcoreSemanticSearchService from "@/services/ProcoreSemanticSearchService";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "ProcoreSemanticSearch",
  title: "Procore | Whistle",
  components: {},
  props: {},
  data() {
    return {
      // Errors
      genericError: false,
      queryError: null,

      // Loading
      loadingInitialState: true,
      loadingClientSetup: false,
      loadingQuery: false,

      // Displays
      displayFilters: false,
      displayImageUpload: false,
      displayImagePopup: false,

      // Data / Values
      clientId: null,
      search: null,
      imageFile: null,
      results: [],
      loadMoreResultsButton: false,
      queryPage: 0,

      // Filters
      startDate: null,
      endDate: null,
      projectName: null,
      uploaderName: null,

      // Popup
      popup: null,

      // v2
      tab: 0,
      keys: {
        myRewards: 0,
        rewardsSent: 1
      },
      display: {
        search: false,
        admin: false,
        popups: false
      },
      popups: [],
      currentPopupIndex: 0
    };
  },
  created() {
    //  First we have to check if a client is already hooked up
    this.checkCompanyState();
  },
  mounted() {},
  destroyed() {},
  methods: {
    async checkCompanyState() {
      if (!this.companyId) return;

      this.loadingInitialState = true;
      try {
        const res = await ProcoreSemanticSearchService.getCompanyClientMapping(
          this.companyId
        );
        console.log("Company output", res);
        this.clientId = res?.result?.clientId;
      } catch (e) {
        console.error("Error checking companyId", e);
        this.genericError = true;
      } finally {
        this.loadingInitialState = false;
      }
    },
    async linkCompany() {
      if (!this.companyId) return;

      this.loadingClientSetup = true;
      try {
        const res = await ProcoreSemanticSearchService.createCompanyClientMapping(
          this.companyId,
          {
            clientName: this.companyName
          }
        );
        console.log("Setup output", res);
        this.clientId = res?.result?.clientId;
      } catch (e) {
        console.error("Error setting up company", e);
        this.genericError = true;
      } finally {
        this.loadingClientSetup = false;
      }
    },
    async searchImages(freshLoad = false) {
      const imageUpload = !!this.displayImageUpload;
      if (
        !this.companyId ||
        (!this.displayImageUpload && !this.search) ||
        (this.displayImageUpload && !this.imageFile) ||
        this.loadingQuery
      )
        return console.log("Ignoring query");
      if (freshLoad) {
        this.loadMoreResultsButton = false;
        this.queryPage = 1;
      } else this.queryPage++;

      this.queryError = null;
      this.loadingQuery = true;

      let body = {
        query: this.search,
        page: this.queryPage
      };
      let formData = new FormData();
      if (imageUpload) {
        formData.append("file", this.imageFile);
        formData.append("page", this.queryPage);
      }
      if (this.displayFilters) {
        if (this.startDate)
          imageUpload
            ? formData.append("startDate", this.startDate)
            : (body.startDate = this.startDate);
        if (this.endDate)
          imageUpload
            ? formData.append("endDate", this.endDate)
            : (body.endDate = this.endDate);
        if (this.uploaderName)
          imageUpload
            ? formData.append("procoreUserName", this.uploaderName)
            : (body.procoreUserName = this.uploaderName);
        if (this.projectName)
          imageUpload
            ? formData.append("projectName", this.projectName)
            : (body.projectName = this.projectName);
      }

      try {
        console.log("New query", body);
        const res = imageUpload
          ? await ProcoreSemanticSearchService.searchImagesByImage(
              this.companyId,
              formData
            )
          : await ProcoreSemanticSearchService.searchImages(
              this.companyId,
              body
            );
        console.log("Query results", res);
        if (freshLoad) this.results = res.result;
        else this.results.push(...(res.result || []));
        this.loadMoreResultsButton = !!this.results?.length;
      } catch (e) {
        console.error("Error setting up company", e);
        this.queryError = "There was an issue performing this search.";
      } finally {
        this.loadingQuery = false;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleFileSearch() {
      this.displayImageUpload = !this.displayImageUpload;
      this.imageFile = null;
      this.search = null;
      this.results = [];
      this.queryPage = 1;
    },
    loadImagePopup(e) {
      this.popup = e;
      this.displayImagePopup = true;
      console.log("Popup loaded", e);
    },
    imageFileChanged(e, dragAndDrop = false) {
      console.log({ e, dragAndDrop });
      let file;
      if (dragAndDrop && e?.dataTransfer?.files?.length)
        file = e?.dataTransfer?.files[0];
      else if (!dragAndDrop && e?.target?.files?.length)
        file = e?.target?.files[0];
      console.log("File", file);
      if (file) {
        this.imageFile = file;
        this.searchImages(true);
      }
    },
    toLocalDate(date, format = false, formatValue = null) {
      if (!date) return;

      const d = moment(date).local();
      if (!format) return d;
      return d.format(formatValue || "MM-DD-YYYY h:mma");
    },
    failedToLoadImage(e) {
      console.log("Failed to load image", e);
      this.$set(this.popup, "failed", true);
      // this.popup.failed = true;
    }
  },
  computed: {
    ...mapState(["userProfile", "roles", "permissions", "magicLinkToken"]),
    companyId() {
      return this.$route.query.companyId;
    },
    companyName() {
      return this.$route.query.companyName;
    },
    projectId() {
      return this.$route.query.projectId;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isTest() {
      return process.env.VUE_APP_ENVIRONMENT == "test";
    },
    computedPopupImage() {
      if (this.popup.failed) return "./placeholder_image.png";
      return this.popup?.raw_path;
    },
    cobrandedCardLogo() {
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom2 &&
        this.userProfile.Client.internalCustom2.startsWith("https")
      )
        return this.userProfile.Client.internalCustom2;
      return null;
    },
    adminView() {
      // Permissions for if they can send payments
      let paymentAdminRoles = [
        "20PaymentsAdminV2",
        "20Payments+GeneralAdminV2",
        "30FinanceAdminV2",
        "40ClientAdminV2"
        // "98WhistleFinanceAdminV2"
      ];
      return (
        this.permissions.includes("wallets:create:cashpayment") ||
        this.roles.find(x => paymentAdminRoles.includes(x))
      );
    },
    displayOtherRewardsCheckbox() {
      let adminRoles = ["30FinanceAdminV2", "40ClientAdminV2"];
      return this.roles.find(x => adminRoles.includes(x));
    },
    currentPopup() {
      if (this.currentPopupIndex == null) return null;
      return this.popups[this.currentPopupIndex];
    }
  },
  watch: {
    // Don't let them load more because they changed the search so it has to be a fresh search / load
    imageFile: function() {
      this.loadMoreResultsButton = false;
    },
    search: function() {
      this.loadMoreResultsButton = false;
    }
  }
};
</script>

<style scoped>
.gradient-background {
  /* background-image: url(https://storage.googleapis.com/whistle-dev-pictures/Logo/gradient.png); */
  background: linear-gradient(
      45deg,
      rgba(161, 215, 110, 1) 1%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}
/* change flex to make footer margin-top auto work to push to bottom */
.v-tabs {
  flex: 0 1 auto;
}

.balance {
  font-size: 1.35em;
}
.balance.in-process {
  color: #999;
}
.border-radius {
  border-radius: 5px;
}

.dispute-button {
  width: 36px;
}

.txn-table {
  /* border: solid 1px;
  border-color: lightgray; */
  border-radius: 20px;
  /* border-radius: 0px; */
}

.txn-table >>> .v-data-table__wrapper {
  border-radius: 20px 0 0 0;
  padding-top: 10px;
}

.txn-table >>> .v-data-table-header {
  background-color: white;
}

.budget-select {
  font-size: 0.875rem;
  min-width: 160px;
}

.robin-animation >>> .robin {
  justify-content: center;
}

.search {
  max-height: 56px !important;
  min-height: 56px !important;
}

.search-field {
  border-radius: 4px 0 0 4px !important;
}

.search-button {
  border-radius: 0 4px 4px 0;
  background-color: var(--v-brandCyan-base) !important;
  border-style: solid !important;
  border-color: grey !important;
  border-width: 1px;
  border-left-width: 0px;
}

.camera-button {
  border-radius: 0 0 0 0;
  background-color: #bdbdbd !important;
  border-style: solid !important;
  border-color: grey !important;
  border-width: 1px;
  border-left-width: 0px;
}

.filter-field {
  max-width: 260px;
}

.advanced-filters-box {
  border-radius: 3px;
  border: 1px solid lightgray;
}
</style>
