import { main as axios } from "../axios";

const baseUrl =
  process.env.VUE_APP_DEV_LOCAL && process.env.VUE_APP_PROCORE_URL
    ? process.env.VUE_APP_PROCORE_URL
    : `${process.env.VUE_APP_WHISTLE_API}/procore-semantic-search-service`;

class ProcoreService {
  getCompanyClientMapping(companyId) {
    return axios
      .get(`${baseUrl}/company/${companyId}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  } //getCompanyClientMapping

  createCompanyClientMapping(companyId, data) {
    return axios
      .post(`${baseUrl}/company/${companyId}`, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  } //createCompanyClientMapping

  searchImages(companyId, data) {
    return axios
      .post(`${baseUrl}/search/${companyId}`, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  } //searchImages

  searchImagesByImage(companyId, formData) {
    return axios
      .post(`${baseUrl}/search-image/${companyId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  } //searchImagesByImage
}

export default new ProcoreService();
